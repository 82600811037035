<template>
  <v-dialog v-model="surveyDrawer"
            max-width="600"
            scrollable>
    <v-card>
      <v-card-title class="my__v-card-title">
        <div>
          <div v-if="surveyMode !== 'edit'"
               class="primary--text">
            เพิ่มการตรวจสอบ
          </div>
          <div v-else
               class="primary--text">
            แก้ไขการตรวจสอบ
          </div>
          <div class="text-caption"
               style="margin-top: -6px;">
            {{ bridgeName || '---' }}
          </div>
        </div>
        <v-icon @click="close">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider/>
      <v-card-text class="black--text">
        <v-form ref="form" class="pt-5">
          <v-row>
            <v-col class="pb-0" cols="12" sm="6">
              ชื่อผู้ตรวจสอบ
              <v-text-field
                v-model="inspectorName"
                :rules="[(value) => !!value || 'ต้องการ']"
                data-textfield="inspectorName"
                class="mt-1"
                dense
                outlined/>
            </v-col>
            <v-col class="pb-0 pt-0 pt-sm-3" cols="12" sm="6">
              เริ่มตรวจสอบ
              <DatePicker
                v-model="inspectionDate"
                :readonly="surveyMode==='edit'"
                :rules="[(value) => !!value || 'ต้องการ']"
                :max="new Date().toISOString().slice(0, 10)"
                data-textfield="inspectionDate"
                class="mt-1"/>
            </v-col>
            <v-col class="pb-0 pt-sm-0 pt-0" cols="12" sm="6">
              ประเภทตรวจสอบ
              <!-- Duplicate ได้แค่การตรวจสอบพิเศษ-->
              <v-select v-model="inspectionType"
                        :items="surveyTypeArray.filter(item => !duplicateInspection || String(item.id) === '3')"
                        :readonly="surveyMode==='edit'"
                        :rules="[(value) => !!value || 'ต้องการ']"
                        data-textfield="inspectionType"
                        class="mt-1"
                        dense
                        item-text="name"
                        item-value="id"
                        outlined/>
            </v-col>
            <v-col v-if="surveyMode !== 'edit'" class="pt-0 pb-4 d-sm-none" cols="12">
              <v-checkbox v-model="duplicateInspection"
                          class="mt-0 pt-0 myCheckbox"
                          dense
                          hide-details
                          @change="$store.dispatch('bridgeDetail/getInspectionList', { id: bridgeId });">
                <template v-slot:label>
                  <span class="black--text" style="font-size: 0.875rem;">คัดลอกการตรวจสอบ</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col v-if="duplicateInspection" class="pb-0 pt-sm-0 pt-0" cols="12" sm="6">
              คัดลอกการตรวจสอบจาก
              <v-select
                v-model="fromInspectionId"
                :items="inspectionSelect"
                :rules="[(value) => !!value || 'ต้องการ']"
                item-text="text"
                item-value="id"
                dense
                outlined
                class="mt-1"/>
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.breakpoint.smAndUp && surveyMode !== 'edit'">
            <v-col class="py-0" cols="12" sm="6">
              <v-checkbox v-model="duplicateInspection"
                          class="mt-0 pt-0 myCheckbox"
                          dense
                          hide-details
                          @change="$store.dispatch('bridgeDetail/getInspectionList', { id: bridgeId });">
                <template v-slot:label>
                  <span class="black--text" style="font-size: 0.875rem;">คัดลอกการตรวจสอบ</span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between px-sm-6 pb-4 pt-0">
        <div v-if="surveyMode !== 'edit'"></div>
        <v-btn v-if="surveyMode === 'edit'"
               color="primary"
               elevation="0"
               outlined
               style="width: 140px;"
               @click="openDeleteDialog">
          ลบการตรวจสอบ
        </v-btn>
        <v-btn color="primary"
               elevation="0"
               style="width: 140px;"
               @click="confirm">
          {{ surveyMode !== 'edit' ? 'เพิ่ม' : 'บันทึก' }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog"
              close-delay="0"
              transition=""
              :max-width="(typeof deleteResult === 'string') ? 250 : 350"
              persistent>
      <v-card>
        <v-card-text class="my__v-card-text">
          <div v-if="!deleteResult"
               class="pa-4 text-center"
               style="font-size: 1.5rem;">
            <v-icon class="warning--text"
                    style="font-size: 57px;">
              mdi-alert-outline
            </v-icon>
            <div class="black--text font-weight-bold mt-4 mb-5"
                 style="font-size: 1.5rem;">
              ต้องการลบการตรวจสอบ
            </div>
            <div class="d-flex justify-space-between"
                 style="width: 100%;">
              <v-btn color="grey"
                     outlined
                     style="height: 32px;"
                     @click="dialog=false">
                ยกเลิก
              </v-btn>
              <v-btn color="blue white--text"
                     elevation="0"
                     style="height: 32px;"
                     @click="deleteForm">
                ยืนยัน
              </v-btn>
            </div>
          </div>
          <div v-else-if="deleteResult==='success'"
               class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              ลบข้อมูลสำเร็จ
            </div>
            <div class="progress-green">
              <div class="color-green"></div>
            </div>
          </div>
          <div v-else class="pa-4 pb-1 text-center">
            <v-icon class="green--text"
                    style="font-size: 57px;">
              mdi-check-circle-outline
            </v-icon>
            <div class="black--text font-weight-bold my-4"
                 style="font-size: 1.5rem;">
              ลบข้อมูลไม่สำเร็จ
            </div>
            <div class="progress-red">
              <div class="color-red"></div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import DatePicker from '../DatePicker.vue'
import { mapGetters, mapState } from 'vuex'
import utils from '@/assets/js/utils'

export default {
  components: { DatePicker },
  data: () => ({
    inspectorName: '',
    inspectionType: '',
    inspectionDate: '',
    dialog: false,
    deleteResult: null,
    duplicateInspection: false,
    fromInspectionId: ''
  }),
  computed: {
    ...mapGetters('damageDetailSupply', { surveyTypeArray: 'surveyTypeArray' }),
    ...mapState('bridgeDetail', { inspectionList: 'inspectionList' }),
    ...mapState('inspectionDamageDialog', { surveyMode: 'surveyMode', bridgeId: 'bridgeId' }),
    ...mapState('appbarText', { bridgeName: 'header' }),
    ...mapState('user', { fullname: 'fullname', uid: 'uid' }),
    surveyDrawer: {
      get () {
        return this.$store.state.inspectionDamageDialog.surveyDrawer
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_SURVEY_DRAWER', to)
      }
    },
    inspectionSelect () {
      const inspectionList = this.inspectionList.filter(item => !this.inspectionType || String(item.type.id) === String(this.inspectionType))
      return inspectionList.map(item => {
        const dateString = item.dateInspectionPeriod.start.slice(0, 10)
        const date = utils.stringToDate(dateString)
        const thaiDate = utils.dateToThai(date)
        item.text = `${item.type.name.split(' ')[0]} (${thaiDate})`
        return item
      })
    }
  },
  methods: {
    close () {
      this.$refs.form.reset()
      this.inspectionDate = ''
      this.surveyDrawer = false
    },
    closeDeleteDialog () {
      this.dialog = false
      if (this.deleteResult === 'success') {
        this.close()
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', false)
      }
    },
    openDeleteDialog () {
      this.dialog = true
    },
    async deleteForm () {
      this.deleteResult = await this.$store.dispatch('inspectionDamageDialog/confirmDeleteInspectionForm') ? 'success' : 'fail'
      setTimeout(this.closeDeleteDialog, 3000)
      await this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
    },
    async confirm () {
      const isFormValid = this.$refs.form.validate()
      if (!isFormValid) return false
      const { inspectionType, inspectorName, inspectionDate, duplicateInspection, fromInspectionId } = this
      const payload = { inspectionType, inspectorName, inspectionDate, duplicateInspection, fromInspectionId }
      await this.$store.dispatch('inspectionDamageDialog/confirmInspectionForm', payload)
      this.$store.commit('inspectionDamageDialog/SET_DAMAGE_DETAIL_TAB', 1)
      if (String(inspectionType) !== '1') {
        await this.$store.dispatch('bridgeDetail/getInspectionList', { id: this.bridgeId, force: true })
      } else {
        // has been fetch in [confirmInspectionForm] -> [setInspectionDialog]
        // await this.$store.dispatch('inspectionDamageDialog/fetchRoutineDialog')
      }
      this.close()
    }
  },
  watch: {
    async surveyDrawer (to) {
      if (to && this.surveyMode === 'edit') {
        const template = await this.$store.dispatch('inspectionDamageDialog/fetchInspectionForm')
        this.inspectorName = String(template.inspectorName)
        this.inspectionType = String(template.inspectionType)
        this.inspectionDate = template.inspectionDate
      }
      if (to && this.surveyMode === 'add') {
        this.inspectionDate = new Date().toISOString().slice(0, 10)
        this.inspectorName = this.fullname || `คุณหมายเลข ${this.uid}`
      }
    },
    dialog (to) {
      this.deleteResult = null
      if (!to) {
        this.closeDeleteDialog()
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 4px;
}

::v-deep .myCheckbox > .v-input__control > .v-input__slot > .v-label {
  width: min-content !important;
}

::v-deep .v-select__selections > .v-select__selection {
  width: 100%;
  max-width: 100%;
}

::v-deep .v-select__selections > input {
  display: none;
}
</style>
