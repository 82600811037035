<template>
  <div v-if="isLoaded"
       class="d-flex fill-height">
    <v-layout class="split">
      <v-col v-if="$vuetify.breakpoint.mdAndUp || !showMap"
             class="left pb-0 pb-sm-3 pa-0"
             cols="12"
             :md="showMap ? 9 : 12">
        <div class="fill-height">
          <v-tabs ref="tabs" v-model="tab" grow>
            <v-tabs-slider color="primary"/>
            <v-tab v-for="(item, index) in tabComponent"
                   :key="index"
                   :data-text="item.name"
                   >
              {{ item.name }}
              <a class="a_forRightClick"
                 @click="changeQuery(index)"/>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab"
                        class="tab"
                        style="height: calc(100% - 48px);"
                        touchless>
            <v-tab-item v-for="(item, index) in tabComponent"
                        :key="index">
              <!--
                Warning:
                This v-if is important
                if not use v-if <BridgeComponent> will not render in dialog
              -->
              <component
                :is="item.component"
                v-if="index === tab"/>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
      <v-col v-if="showMap"
             class="pa-0"
             cols=""
             md="3">
        <longdo-map v-if="marker"
                    :lastView="false"
                    :location="marker.location"
                    :zoom="14"
                    class="pa-0 ma-0"
                    @load="loadMap">
          <longdo-map-marker
            v-if="marker"
            :location="marker.location"
            :title="marker.title"/>
        </longdo-map>
      </v-col>
    </v-layout>
    <v-btn bottom
           data-target="openMap"
           class="white--text blue mb-6"
           fab
           fixed
           right
           @click="setShowMap">
      <v-icon v-if="showMap">mdi-menu</v-icon>
      <v-icon v-else color="white">mdi-map-outline</v-icon>
    </v-btn>
    <v-btn bottom
           class="white--text hidden-sm-and-up green mb-6"
           fab
           fixed
           right
           style="right: 80px;"
           @click="$store.dispatch('inspectionDamageDialog/openNewInspection')">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <AddSurveyDialog/>
    <inspection-damage-dialog/>
  </div>
</template>

<script>
import AddSurveyDialog from '../components/dialog/AddSurveyDialog.vue'
import utils from '@/assets/js/utils'
import InspectionDamageDialog from '@/components/inspection/InspectionDialog/InspectionDamageDialog'

export default {
  components: { InspectionDamageDialog, AddSurveyDialog },
  beforeMount () {
    this.tab = parseInt(this.$route.query.tab || 0)
    const bridgeId = this.$route.query.id.trim()
    this.$store.commit('inspectionDamageDialog/SET_BRIDGE_ID', bridgeId)
  },
  async mounted () {
    this.$store.dispatch('damageDetailSupply/fetchSurveyItem').then(() => {})
    const promise = this.getBridgeOverview()
    await this.getBridgeDetail()
    await promise
    this.setAppbar()
    this.computeMaker()
  },
  data: () => ({
    getKm: utils.getKm,
    bridgeDetail: {},
    marker: false,
    showMap: false,
    isMapDialogShow: false,
    tabComponent: [
      {
        name: 'ข้อมูลพื้นฐานสะพาน',
        component: () => import(/* webpackChunkName: "bridge-detail" */ '../components/damageDetail/BridgeDetailTab')
      },
      {
        name: 'ประวัติการตรวจสอบ',
        component: () => import(/* webpackChunkName: "history-survey" */ '../components/damageDetail/HistorySurveyTab')
      }
    ],
    isLoaded: false
  }),
  computed: {
    tab: {
      get () {
        return this.$store.state.inspectionDamageDialog.damageDetailTab
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DAMAGE_DETAIL_TAB', to)
      }
    }
  },
  methods: {
    loadMap (map) {
      setTimeout(map.resize, 200)
    },
    async getBridgeDetail () {
      const payload = { id: this.$route.query.id }
      this.bridgeDetail = await this.$store.dispatch('bridgeDetail/getBridgeDetail', payload)
    },
    computeMaker () {
      const info = this.bridgeDetail.info
      const geom = String(info.geom)
      const location = utils.getLocationFromPointString(geom)
      this.marker = { title: info.name, location }
    },
    setAppbar () {
      const info = this.bridgeDetail.info
      const header = `
        ${info.name}
        ${info.direction ? `(${info.direction})` : ''}
      `
      const subHeader = `
        ทล. ${parseInt(info.road_code)}
        กม. ${this.getKm(info.km || 0)}
      `
      this.$store.commit('appbarText/SET_HEADER', header)
      this.$store.commit('appbarText/SET_SUB_HEADER', subHeader)
      this.$store.commit('appbarText/SET_DEFAULT_ROUTE', 'Damage')
    },
    changeQuery (tab) {
      const query = Object.assign({}, this.$route.query)
      query.tab = String(tab)
      this.$router.replace({ query })
        .catch(() => {}) // remove error from console log // this error mostly appear when navigate on same route
    },
    async getBridgeOverview () {
      const payload = { bridgeCode: this.$route.query.id }
      await this.$store.dispatch('getBridgeOverview', payload)
      this.isLoaded = true
    },

    setShowMap () {
      this.showMap = !this.showMap
      this.$refs.tabs.onResize()
    }
  }
}
</script>

<style lang="scss" scoped>
.split {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.left {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

@media screen and (min-width: 600px) {
  .left {
    width: min(66.66%, 800px);
    min-width: min(66.66%, 800px);
  }

  .tab {
    overflow: hidden;
  }
}

@media screen and (max-width: 600px) {
  .tab {
    overflow: auto;
  }
}

::v-deep .v-window__container, ::v-deep .v-window-item--active {
  height: 100%;
}

::v-deep .v-slide-group__prev--disabled, ::v-deep .v-slide-group__next--disabled {
  display: none !important;
}
</style>
