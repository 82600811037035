<template>
  <v-dialog v-model="drawer"
            :fullscreen="$vuetify.breakpoint.xs"
            :max-width="950"
            :transition="transition"
            persistent
            scrollable>
    <normal-form
      v-if="String(inspectionType) === '1'"
      :class="{
        'dialog85': step !== 2,
        'dialog650': step === 2
      }"/>
    <other-form-mobile
      v-else
      :class="{
        'dialog85': step !== 2,
        'dialog650': step === 2
      }"/>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'InspectionDamageDialog',
  components: {
    OtherFormMobile: () => import('@/components/inspection/InspectionDialog/OtherFormMobile'),
    NormalForm: () => import('@/components/inspection/InspectionDialog/NormalForm')
  },
  mounted () {
    this.$store.dispatch('ref/bridgeComponentMaterial')
    this.$store.dispatch('ref/getRef', { method: 'bridge-component-pole' })
    this.$store.dispatch('ref/getRef', { method: 'v-filter-unit-principal' })
    this.$store.dispatch('getBridgeComponent')
  },
  computed: {
    ...mapState('inspectionDamageDialog', { inspectionType: 'inspectionType', svgStep: 'svgStep', step: 'step' }),
    transition () {
      return this.$vuetify.breakpoint.xs ? 'dialog-bottom-transition' : 'dialog-transition'
    },
    drawer: {
      get () {
        return this.$store.state.inspectionDamageDialog.drawer
      },
      set (to) {
        this.$store.commit('inspectionDamageDialog/SET_DRAWER', to)
        setTimeout(() => {
          this.$store.commit('inspectionDamageDialog/SET_STEP', 0)
          this.$store.commit('inspectionDamageDialog/SVG_STEP', 0)
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.dialog85 {
  min-height: 85vh;
}

.dialog650 {
  max-height: 100%;
}
</style>
